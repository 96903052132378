import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { RouterProvider } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import routes from './AppRouter'; // Import the routes
import { AlertProvider } from './components/alertProvider';
import { LoaderProvider } from './components/loaderProvider/loader';

const App: React.FC = () => {

  useEffect(() => {
    // Apply the linear gradient to the body dynamically
    document.body.style.background = 
      'linear-gradient(72deg, #FFF 0%, #FFF 9.67%, #F6F6F6 32.14%, #ECEEF0 100%), ' +
      'linear-gradient(72deg, #FFF 0%, #FFF 9.67%, #F6F6F6 32.14%, #ECEEF0 100%)';
    
    document.body.style.backgroundSize = 'cover';  // Ensure the gradient covers the full screen

    // Optional cleanup function to reset background on component unmount
    return () => {
      document.body.style.background = '';
    };
  }, []);  // O
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <AlertProvider>
          <LoaderProvider>
            <RouterProvider router={routes} /> {/* Routes are rendered here */}
          </LoaderProvider>
        </AlertProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
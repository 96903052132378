import { BasicPatientInfo, loginDetails, PatientListResponse, PatientResponse, Patientv2, ApiResponse, User, File_Source_Req, File_Source_Res, ApiResponseState } from '../types';
import { getRequest, postRequest, putRequest, uploadFileToS3 } from './httpService';
import { GenderResponse } from '../types';
import { getUserUrl, postNewRegistrationUrl,getPatientApiUrl, geGenderUrl, loginUserUrl, getLinkPartnerUrl, getDelinkUrl, getPatientApiUrlById, getcountryUrl, getStateUrl, getMyProfileUrl, getUploadURL, getcountrycodeUrl , getDoctorsList, addMenstrualHistoryURL, addSexualHistoryURL, getMenstrualHistoryURL, addSocialHistoryURL, getSocialHistoryURL, getappointmentsUrl, getappointmentallergysUrl, putappointmentallergysUrl, getappointmentaddictionsUrl, putappointmentaddictionsUrl, putappointmentpreviousinvestigationhistoryUrl, getputpriorinfertilitytestingUrlUrl, putpriorinfertilitytestingUrl} from './urls';
import { AxiosResponse } from 'axios';

// Purpose: Contains functions that make requests to the API.
export function getUser(name: string): Promise<string> {
  return getRequest(getUserUrl(name), { responseType: 'text' });
}

export function loginUser(loginData: loginDetails): Promise<string> {
  return postRequest<'json'>(loginUserUrl(),loginData);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchPatientList(query: string): Promise<any> {
  const url = getPatientApiUrl();
  const response = await getRequest(`${url}?${query}`, { responseType: 'json' });
  return response;
}


export function getGender(): Promise<GenderResponse> {
  return getRequest(geGenderUrl(), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postPatientPartnerInfo(data:any): Promise<Patientv2> {
  return postRequest<Patientv2>(postNewRegistrationUrl(),data, { responseType: 'json' });
}

export function getLinkPatientList(): Promise<PatientListResponse> {
  return getRequest(getPatientApiUrl(), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postLinkPartner(partnerId: any, data: any): Promise<AxiosResponse<PatientResponse>> {
  return postRequest(getLinkPartnerUrl(partnerId), data, { responseType: 'json' });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postDelink(patientId: any, data: any): Promise<string> {
  return postRequest(getDelinkUrl(patientId), data, { responseType: 'json' });
}

export function getcountryList(): Promise<ApiResponse> {
  return getRequest(getcountryUrl(), { responseType: 'json' });
}

export function getCountryStateList(selectedCountry: string): Promise<ApiResponseState> {
  // Construct the request body according to the API specifications
  const requestBody = JSON.stringify({ country: selectedCountry });

  return postRequest(getStateUrl(), requestBody, { responseType: 'json' });
}


export function getStateList(): Promise<ApiResponse> {
  return postRequest(getStateUrl(), { responseType: 'json' });
}
export function getPatientDetails(id: string): Promise<Patientv2> {
  return getRequest(getPatientApiUrlById(id), { responseType: 'json' });
}

export function getPatientName(id: string): Promise<BasicPatientInfo> {
  return getRequest(getPatientApiUrlById(id), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updatePatient(id: string,data:any): Promise<BasicPatientInfo> {
  return putRequest(getPatientApiUrlById(id),data, { responseType: 'json' });
}


export function getMyProfile(): Promise<User> {
  return getRequest<User>(getMyProfileUrl(), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function uploadFileURL(req: File_Source_Req, file: File):Promise<any> {
  return postRequest<File_Source_Res>(getUploadURL(), req, { responseType: 'text' })
    .then((response: File_Source_Res) => {
      return uploadFileToS3(file, response.presigned_url);
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getcountrycode(): Promise<any> {
  return getRequest(getcountrycodeUrl(), { responseType: 'json' });
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getDoctors(name: string = '', limit: number = 10): Promise<any> {
  const url = `${getDoctorsList()}?limit=${limit}&name__icontains=${name}`;
  return getRequest(url, { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMenstrualHistory(id: string): Promise<any> {
  return getRequest(getMenstrualHistoryURL(id), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addMenstrualHistory(id: string,data:any): Promise<BasicPatientInfo> {
  return putRequest(addMenstrualHistoryURL(id),data, { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addSexualHistory(id: string,data:any): Promise<any> {
  return putRequest(addSexualHistoryURL(id),data, { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSocialHistory(id: string): Promise<any> {
  return getRequest(getSocialHistoryURL(id), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addSocialHistory(id: string,data:any): Promise<any> {
  return putRequest(addSocialHistoryURL(id),data, { responseType: 'json' });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getappointmentsList(): Promise<any> {
  return getRequest(getappointmentsUrl(), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getappointmentallergysList(patientId: number): Promise<any> {
  return getRequest(getappointmentallergysUrl(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putappointmentallergysList(patientId: number, allergies: any[]): Promise<any> {
  return putRequest(putappointmentallergysUrl(patientId), allergies); 
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getappointmentaddictionsList(patientId: number): Promise<any> {
  return getRequest(getappointmentaddictionsUrl(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putappointmentaddictionsList(patientId: number, addiction: any[]): Promise<any> {
  return putRequest(putappointmentaddictionsUrl(patientId), addiction); 
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getappointmentpreviousinvestigationhistoryList(patientId: number): Promise<any> {
  return getRequest(putappointmentpreviousinvestigationhistoryUrl(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putappointmentpreviousinvestigationhistoryList(patientId: number, investigationHistory: any): Promise<any> {
  return putRequest(putappointmentpreviousinvestigationhistoryUrl(patientId), investigationHistory); 
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getpriorinfertilitytesting(patientId: number): Promise<any> {
  return getRequest(getputpriorinfertilitytestingUrlUrl(patientId), { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function putpriorinfertilitytesting(patientId: number, investigationHistory: any): Promise<any> {
  return putRequest(putpriorinfertilitytestingUrl(patientId), investigationHistory); 
}

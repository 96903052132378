// Purpose: Contains functions that return URLs for the API.
export function getUserUrl(name: string): string {
  return `/user/${name}`;
}
export function loginUserUrl(): string {
  return 'login/';
}
export function postNewRegistrationUrl(): string {
  return 'patients/';
}

export function getPatientApiUrl(): string {
  return 'patients/';
}

export function geGenderUrl(): string {
  return 'genders/';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLinkPartnerUrl(patientId: any): string {
  return `/patients/${patientId}/link-partner/`;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getDelinkUrl(patientId: any): string {
  return `/patients/${patientId}/delink-partner/`;
}

export function getcountryUrl(): string {
  return 'https://countriesnow.space/api/v0.1/countries';
}

export function getStateUrl(): string {
  return 'https://countriesnow.space/api/v0.1/countries/states';
}
export function getPatientApiUrlById(id: string): string {
  return `patients/${id}`;
}

export function getMyProfileUrl(): string {
  return 'me/profile';
}

export function getUploadURL(): string {
  return 'uploads/';
}

export function getcountrycodeUrl(): string {
  return 'https://countriesnow.space/api/v0.1/countries/codes';
}


export function getDoctorsList(): string {
  return 'doctors/';
}

export function getMenstrualHistoryURL(id: string): string {
  return `patients/${id}/menstrual-history/`;
}

export function addMenstrualHistoryURL(id: string): string {
  return `patients/${id}/menstrual-history/`;
}

export function addSexualHistoryURL(id: string): string {
  return `patients/${id}/sexual-history/`;
}

export function getSocialHistoryURL(id: string): string {
  return `patients/${id}/social-history/`;
}

export function addSocialHistoryURL(id: string): string {
  return `patients/${id}/social-history/`;
}
export function getappointmentsUrl(): string {
  return 'appointments/';
}

export function getappointmentallergysUrl(patientId: number): string {
  return `/patients/${patientId}/allergy/`; 
}

export function putappointmentallergysUrl(patientId: number): string {
  return `/patients/${patientId}/allergy/`; 
}

export function getappointmentaddictionsUrl(patientId: number): string {
  return `/patients/${patientId}/addiction/`; 
}

export function putappointmentaddictionsUrl(patientId: number): string {
  return `/patients/${patientId}/addiction/`; 
}


export function getappointmentpreviousinvestigationhistoryUrl(patientId: number): string {
  return `/patients/${patientId}/previous-investigation-history/`; 
}

export function putappointmentpreviousinvestigationhistoryUrl(patientId: number): string {
  return `/patients/${patientId}/previous-investigation-history/`; 
}


export function getputpriorinfertilitytestingUrlUrl(patientId: number): string {
  return `/patients/${patientId}/prior-infertility-testing/`; 
}

export function putpriorinfertilitytestingUrl(patientId: number): string {
  return `/patients/${patientId}/prior-infertility-testing/`; 
}

import React from 'react';
import styles from './TopbarComponent.module.css';
import logo from '../../assets/SVG/Clinic Logo.png';
import calander from '../../assets/SVG/white calendar.svg';
// import notification from '../../assets/SVG/White Notification.svg';
// import message from '../../assets/SVG/White Message.svg';
import MuiBreadcrumbs from '../breadcrumbComponent';
import DefaultImageMale from '../../assets/SVG/Default Profile - Male.svg';
import { Avatar, Typography } from '@mui/material';
import UserSettingsComponent from '../userSettingsComponent';
import { DrawerComponent } from '../drawerComponent';
import { getMyProfile } from '../../services/apiService';
import { User } from '../../types';

const TopBarComponent: React.FC = () => {

  const [user, setUser] = React.useState<User | null>(null);

  React.useEffect(() => {
    getMyProfile().then((response: User) => {
      setUser(response);
      localStorage.setItem('user', JSON.stringify(response));
    });
  }, []);


  return (
    <div className={styles.bar}>
      <div className={styles.drawer}>
        <DrawerComponent/>
      </div>
      <div className={styles.logo_container}>
        <img src={logo} alt='logo' />
      </div>
      <div className={styles.bd_container}>
        <MuiBreadcrumbs/>
        <div className={styles.end}>
          <div>
            {user && <Typography variant='body1' color='textPrimary'>Clinic: {user.tenant.name? user.tenant.name: '-'}</Typography>}
          </div>
          <div className={styles.icons}>
            <div className={styles.icn_container}>
              <img src={calander} alt='calander' />
            </div>
          </div>
          <div className={styles.profile}>
            {user && <Avatar alt={user?.username} src={DefaultImageMale} />}
            <div>
              {user && <UserSettingsComponent name={user.username} user={user.id + ''}/>}
              {user && <Typography variant='body1' color='textSecondary'>{user.designation? user.designation: 'Receptionist'}</Typography>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBarComponent;
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import style from './AppointmentTable.module.css';
import { Appointment, AppointmentData, ColumnConfig, GroupConfig } from '../../types';
import EditableTable from '../customTableComponent';
import { useLoader } from '../loaderProvider/loader';
import { useAlert } from '../alertProvider';
import { getappointmentsList } from '../../services/apiService';
import { ReactComponent as VectorIcon } from '../../assets/SVG/dotline.svg';

const groupConfig: GroupConfig[] = [
  { fields: ['date'], type: 'row' },
  { fields: ['TimeFrom'], type: 'row' },
  { fields: ['TimeTo'], type: 'row' },
  { fields: ['patientName', 'MRN'], type: 'column', defaultHeaderGroupname: 'Patient Name' },
  { fields: ['mobileNumber'], type: 'row' },
  { fields: ['department'], type: 'row' },
  { fields: ['doctor'], type: 'row' },
  { fields: ['Reason'], type: 'row' },
  { fields: ['status'], type: 'row' },
];

const columns: ColumnConfig[] = [
  { field: 'date', headerName: 'Date', type: 'date', editable: false },
  { field: 'TimeFrom', headerName: ' Time From', type: 'text', editable: false },
  { field: 'TimeTo', headerName: 'Time To', type: 'text', editable: false },
  { field: 'patientName', headerName: 'Patient Name', type: 'text', editable: false },
  { field: 'MRN', headerName: 'MRN', type: 'text', editable: false },
  { field: 'mobileNumber', headerName: 'Mobile Number', type: 'text', editable: false },
  { field: 'doctor', headerName: 'Doctor', type: 'text', editable: false },
  { field: 'department', headerName: 'Department', type: 'text', editable: false },
  { field: 'Reason', headerName: 'Reason', type: 'text', editable: false },
  { field: 'status', headerName: 'Status', type: 'text', editable: false },
];

export default function AppointmentTable() {
  const { showLoader, hideLoader } = useLoader();
  const { addAlert } = useAlert();
  const [appointmentData, setAppointmentData] = useState<AppointmentData[]>([]);
  const [dataSize, setDataSize] = useState<number | undefined>();

  const handleOnChange = (id: number, field: string, value: string) => {
    console.log('On Change:', { id, field, value });
  };

  const handleAction = (id: number) => {
    console.log('Action Triggered for ID:', id);
  };

  const formatTimeTo12Hour = (time: string) => {
    const [hour, minute] = time.split(':');
    let hours = parseInt(hour, 10);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; 
    return `${hours}:${minute} ${ampm}`;
  };

  useEffect(() => {
    showLoader();
  
    const fetchAppointments = async () => {
      try {
        const appointmentDataResponse = await getappointmentsList();
        console.log('Appointments Data:', appointmentDataResponse.objects);
  
        setDataSize(appointmentDataResponse.meta.total_count);
  
        const transformedAppointments: AppointmentData[] = appointmentDataResponse.objects.map((appointment: Appointment) => {
          const createdDate = new Date(appointment.appointment_date);
          
          return {
            id: appointment.id,
            gender: appointment.patient?.gender || '-', 
            age: appointment.patient?.age,  
            reasonOfVisit: appointment.patient?.reason_for_visit || '-',
            date: `${createdDate.getUTCDate().toString().padStart(2, '0')}/${(createdDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${createdDate.getUTCFullYear()}`,
            TimeFrom: formatTimeTo12Hour(appointment.start_time),
            TimeTo: formatTimeTo12Hour(appointment.end_time),
            patientName: `${appointment.patient?.first_name || ''} ${appointment.patient?.last_name || ''}`.trim() || '-',
            MRN: `${appointment.patient?.medical_record_no || '-'} | ${appointment.patient?.gender || '-'}`, 
            mobileNumber: appointment.patient?.mobile_number || '-',
            doctor: appointment.doctor_name || 'Dr. Patrick Steptoe',
            firstvisit: appointment.department__name || '-',
            status: (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ cursor: 'pointer' }}
              >
                <VectorIcon />
              </div>
            ),
            appointmentDate: appointment.appointment_date || '-',
            appointmentReason: appointment.appointment_reason__reason?.toString ? appointment.appointment_reason__reason.toString() : '-', 
            department: appointment.department__name || '-',
            Reason: appointment.appointment_reason__reason || '-', // Map the reason for visit correctly here
            startTime: appointment.start_time || '-',
            endTime: appointment.end_time || '-',
            patient: `${appointment.patient?.first_name || ''} ${appointment.patient?.last_name || ''}`.trim() || '-',
          };
        });
          
          
          
  
        setAppointmentData(transformedAppointments);
      } catch (error) {
        console.error('Error fetching appointments:', error);
        addAlert('Error fetching appointments. Please try again later.', 'error');
      } finally {
        hideLoader();
      }
    };
  
    fetchAppointments();
  }, [addAlert, showLoader, hideLoader]);
  
  return (
    <div className={style.container}>
      <Grid container spacing={2} className={style.container} alignItems="center" sx={{ mt: 2, px: 2 }}> 
        <Grid item xs={6}>
          <h2 className={style.title}>Patients List</h2>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button className={style.button} variant="contained">
          New Appointment
          </Button>
        </Grid>
      </Grid>
      <div className="mt-4">
        <EditableTable
          columns={columns}
          data={appointmentData}
          actionType="none"
          onAction={handleAction}
          onChange={handleOnChange}
          dataSize={dataSize}
          groupConfig={groupConfig}
        />
      </div>
    </div>
  
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom/client';
import ErrorBoundary from './ErrorBoundary';
import App from './App';

// Save the original createElement function
const originalCreateElement = React.createElement;

const createElement = (type: any, props: any, ...children: any[]): ReactElement | null => {
  return originalCreateElement(
    ErrorBoundary,
    null,
    originalCreateElement(type, props, ...children)
  );
};


const addLocalScriptForProduction = () => {
  if (process.env.REACT_APP_RELEASE) {
    const script = document.createElement('script');
    script.src = `${process.env.PUBLIC_URL}/newrelic.js`; // Path to your local script file
    script.async = true;
    document.head.appendChild(script);
  }
};

// Call the function to inject the script in production
addLocalScriptForProduction();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// Override React.createElement with our custom implementation
// eslint-disable @typescript-eslint/no-unused-vars 
React.createElement = createElement;

// Use createRoot instead of ReactDOM.render
const container = document.getElementById('root');
if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(<App />);
}
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import TopBarComponent from '../components/topBarComponent';
import SideBarComponent from '../components/sidebarComponent';
import style from './MainLayout.module.css';
import { setNavigateFunction } from '../services/nevigationService';

const MainLayout: React.FC = () => {

  const navigate = useNavigate();
  
  // Set the navigate function in the service
  useEffect(() => {
    setNavigateFunction(navigate);
  }, [navigate]);

  return (
    <div className={style.main}>
      <TopBarComponent/>
      <div className={style.content}>
        <div className={style.sidebar}>
          <SideBarComponent/>
        </div>
        <main className={style.main_page}>
          <div className={style.outletContent}>
            <Outlet />  {/* This renders the child routes like Dashboard */}
          </div>
        </main>
      </div>
    </div>
  );
};

export default MainLayout;